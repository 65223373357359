import React, { useEffect, useState } from 'react';
import { Box, Container, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { css } from "styled-components";

export const ScrollBarStyle = css`
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 6px;
    }
    ::-webkit-scrollbar {
        width: 7px;
        background-color: transparent;
        height: 7px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        background: #888;
        width: 5px;
        border-radius: 6px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}

export default function FnMenu({ listTab, valueInitial }) {
  const history = useHistory()
  const [value, setValue] = useState(history.location.hash.replace("#", ""));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("950"));

  const valueDefault = value || valueInitial

  useEffect(() => {
    async function routeTab() {
      if (!listTab.some(l => l.value === value)) {
        history.push(`#${valueInitial}`)
        setValue(valueInitial);
      }
    }
    routeTab()
  }, [value, listTab, valueInitial, history]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    history.push(`#${newValue}`)
  };

  return (

    <Container style={{ maxWidth: "unset", padding: 0 }}>

      <Box
        style={{
          backgroundColor: "rgb(0 0 0 / 68%)",
          boxShadow: "rgb(0, 0, 0) 0px 15px 50px 1px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "row",
        }}
        sx={{ borderColor: 'divider' }}
      >

        <Tabs
          value={valueDefault}
          onChange={handleChange}
          variant={isMobile ? "fullWidth" : ""}
          aria-label="Menu"
          centered
          textColor="aliceblue"
          sx={{
            ".Mui-selected": {
              color: `orange !important`,
            },
            ".MuiTabs-indicator": {
              backgroundColor: `orange`,
            }
          }}
        >
          {listTab.map((a, i) => (<Tab style={{ color: "aliceblue" }} key={i} value={a.value} label={a.label} />))}
        </Tabs>
      </Box>

      {listTab.map((a, i) => (
        <TabPanel value={valueDefault} index={a.value}>
          {a.pageRedirect}
        </TabPanel>
      ))}

    </Container>
  )

}
