import React from 'react';
import styled from "styled-components";
import FnMenu, { ScrollBarStyle } from '../../components/Menu';
import SocialMedia from '../SocialMedia/Index';
// import Curriculo from '../Curriculo/Index';
import Contact from '../Contact/Index';
import imageBack from '../../assets/homePageBackground.jpg'
import logo from '../../assets/logoMettePrim.gif'

const DivBody = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #262626;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`
const listTab = [
  {
    label: "Redes Sociais",
    value: "redeSocial",
    pageRedirect: <SocialMedia />
  },
  // {
  //   label: "Currículo",
  //   value: "curriculo",
  //   pageRedirect: <Curriculo />
  // },
  {
    label: "Entre em contato",
    value: "contato",
    pageRedirect: <Contact />
  },
]

export const SubBodyHomePage = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: calc(100vh - 50px);
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media(max-width: 999px) {
    height: calc(100vh - 60px);
  }

  ${ScrollBarStyle}
`

function HomePage() {
  return (
    <DivBody style={{ backgroundImage: `url(${imageBack})` }}>
      <FnMenu
        listTab={listTab}
        valueInitial="redeSocial"
      />
    </DivBody>
  );
}

export function FnPage({ children }) {
  return (
    <SubBodyHomePage>
      {children}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          margin: "10px"
        }}
      >

        <img
          style={{ width: "70px" }}
          src={logo}
          alt="Logo Mette"
        />
      </div>
    </SubBodyHomePage>
  );
}

export default HomePage;