import React from 'react';
import { Typography } from '@mui/material';
import { FnPage } from '../Home/Index';
import { styled } from 'styled-components';
import FotoLindedin from "./../../assets/PerfilLinkedin.jpeg";
import LogoYoutube from "./../../assets/SImbolLogoYoutube.png";
import FotoYoutube from "./../../assets/FotoPerfilYoutube.jpg";
import LogoWhatsappPNG from "./../../assets/LogoWhatsappPNG.png";
import LogoInstagramPNG from "./../../assets/LogoInstagram.png"
import LogoFastbuilt from "./../../assets/LogoFastbuilt.ico"
import LogoFacebookPNG from "./../../assets/LogoFacebookPNG.png"
import PerfilFacebook from "./../../assets/PerfilFacebook.jpg"
import PerfilGitHub from "./../../assets/PerfilGitHub.png"
import FotoPerfilInstagram from "./../../assets/FotoPerfilInstagram.jpg"

import { ButtonLink } from '../../components/Button/ButtonLink';

const DivCard = styled.div`
  background-Color: rgba(0, 0, 0, 75%);
  border-Radius: 10px;
  min-height: 165px;
  width: 35vw;
  max-width: 400px;
  padding: 5px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  box-Shadow: rgb(0, 0, 0) 10px 10px 20px 0px;
  color: white;

  @media(max-width: 999px) {
    width: 85vw;
    margin: 10px;
  }

  &:hover{
    color: orange;
  };
`

const TypographyStyledJehh = styled(Typography)`
  color: #e2dbe7;
  &:hover{
    color: #ff00b5;
  };
`

const TypographyStyledFastbuilt = styled(Typography)`
  color: #bce6ff;
  display: flex;

  &:hover{
    color: #7c9bd5;
  };
`

const DivSomeCard = styled.div`
  display: flex;
  justify-Content: space-evenly;
  margin-top: 40px;

  @media(max-width: 999px) {
    flex-Direction: column;
    margin-top: 0px;
  }
`
export function openGuiaNewUrl(url) {
  if (url) {
    window.open(url, '_blank');
  }
}

export default function SocialMedia() {

  const messageWhats = `Bom dia!
  
*(Menssagem automátizada através do site Mette)*`

  return (
    <FnPage>
      <div style={{ marginTop: "5px", padding: "10px", display: "flex", flexDirection: "column" }}>

        <DivSomeCard>

          <ButtonLink onClick={() => openGuiaNewUrl("https://www.linkedin.com/in/fernandomette/")}>
            <DivCard>
              <div style={{ display: "flex", padding: "5px", alignItems: "center" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="currentColor"
                  class="mercado-match" width="24" height="24" focusable="false" style={{ color: "#0a66c2" }}
                >
                  <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
                </svg>
                &nbsp;&nbsp;
                <Typography variant='h6'>Linkedin</Typography>
              </div>

              <div style={{ display: "flex", padding: "5px", alignItems: "center", justifyContent: "flex-start" }}>

                <img
                  style={{ width: '100px', borderRadius: "100px" }}
                  src={FotoLindedin}
                  alt="Foto de perfil Linkedin"
                />

                &nbsp;&nbsp;&nbsp;
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "start" }}>
                  <Typography style={{ fontWeight: "500" }}> Fernando Mette </Typography>
                  <Typography variant='body2'> Desenvolvedor Full Stack e Engenheiro Civil, atualmente trabalhando na empresa
                    &nbsp;
                    <ButtonLink onClick={(e) => { e.stopPropagation(); openGuiaNewUrl(`https://www.linkedin.com/company/fastbuilt/`) }}>
                      <TypographyStyledFastbuilt variant='body2'>Fastbuilt</TypographyStyledFastbuilt>
                    </ButtonLink>


                  </Typography>
                </div>

              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ButtonLink onClick={(e) => { e.stopPropagation(); e.preventDefault(); openGuiaNewUrl(`https://www.fastbuilt.com.br/ `) }}>

                  <TypographyStyledFastbuilt variant='body2'>
                    <img
                      style={{ width: '20px', marginLeft: "5px", marginRight: "5px" }}
                      src={LogoFastbuilt}
                      alt="Logo Fastbuilt"
                    />
                    Fastbuilt
                  </TypographyStyledFastbuilt>
                </ButtonLink>
              </div>

            </DivCard>
          </ButtonLink>

          <ButtonLink onClick={() => openGuiaNewUrl("https://github.com/fernandomette")}>
            <DivCard>
              <div style={{ display: "flex", padding: "5px", alignItems: "center" }}>
                <svg
                  height="32" aria-hidden="true" viewBox="0 0 16 16" version="1.1" width="32" fill="currentColor"
                  data-view-component="true" class="octicon octicon-mark-github v-align-middle color-fg-default"
                  style={{ color: "white" }}
                >
                  <path d="M8 0c4.42 0 8 3.58 8 8a8.013 8.013 0 0 1-5.45 7.59c-.4.08-.55-.17-.55-.38 0-.27.01-1.13.01-2.2 0-.75-.25-1.23-.54-1.48 1.78-.2 3.65-.88 3.65-3.95 0-.88-.31-1.59-.82-2.15.08-.2.36-1.02-.08-2.12 0 0-.67-.22-2.2.82-.64-.18-1.32-.27-2-.27-.68 0-1.36.09-2 .27-1.53-1.03-2.2-.82-2.2-.82-.44 1.1-.16 1.92-.08 2.12-.51.56-.82 1.28-.82 2.15 0 3.06 1.86 3.75 3.64 3.95-.23.2-.44.55-.51 1.07-.46.21-1.61.55-2.33-.66-.15-.24-.6-.83-1.23-.82-.67.01-.27.38.01.53.34.19.73.9.82 1.13.16.45.68 1.31 2.69.94 0 .67.01 1.3.01 1.49 0 .21-.15.45-.55.38A7.995 7.995 0 0 1 0 8c0-4.42 3.58-8 8-8Z"></path>
                </svg>
                &nbsp;&nbsp;
                <Typography variant='h6'>Github</Typography>
              </div>

              <div style={{ display: "flex", padding: "5px", alignItems: "center", justifyContent: "flex-start" }}>

                <img
                  style={{ width: '100px', borderRadius: "100px" }}
                  src={PerfilGitHub}
                  alt="Foto de perfil GitHub"
                />
                &nbsp;&nbsp;&nbsp;
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "start" }}>
                  <Typography style={{ fontWeight: "500" }}> Fernando Mette </Typography>
                  <Typography variant='body2'> Desenvolvedor Full Stack </Typography>
                  <Typography variant='body2'> Nodejs, React, C#, Mariadb, Orm(Sequelize) </Typography>
                </div>
              </div>
            </DivCard>
          </ButtonLink>

        </DivSomeCard>

        <DivSomeCard>

          <ButtonLink onClick={() => openGuiaNewUrl("https://www.youtube.com/@MettePrim")}>
            <DivCard>
              <div style={{ display: "flex", padding: "5px", alignItems: "center" }}>

                <img
                  style={{ width: '35px' }}
                  src={LogoYoutube}
                  alt="logo youtube"
                />
                &nbsp;&nbsp;
                <Typography variant='h6'>Youtube</Typography>
              </div>

              <div style={{ display: "flex", padding: "5px", alignItems: "center", justifyContent: "flex-start" }}>

                <img
                  style={{ width: '100px', borderRadius: "100px" }}
                  src={FotoYoutube}
                  alt="Foto de perfil Youtube"
                />
                &nbsp;&nbsp;&nbsp;
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "start" }}>
                  <Typography style={{ fontWeight: "500" }}> Mette e Prim </Typography>
                  <Typography variant='body2'> Cada vez mais crescendo a biblioteca de vídeos, um histórico das passagens da vida, filmar é tudo ! </Typography>
                </div>
              </div>
            </DivCard>
          </ButtonLink>

          <ButtonLink onClick={() => openGuiaNewUrl(`https://wa.me/47984422902?text=${encodeURIComponent(messageWhats)}`)}>
            <DivCard>
              <div style={{ display: "flex", padding: "5px", alignItems: "center" }}>

                <img
                  style={{ width: '30px' }}
                  src={LogoWhatsappPNG}
                  alt="Logo Whatsapp"
                />

                &nbsp;&nbsp;
                <Typography variant='h6'>Whatsapp</Typography>
              </div>

              <div style={{ display: "flex", padding: "5px", alignItems: "center", justifyContent: "flex-start" }}>

                <img
                  style={{ width: '100px', borderRadius: "100px" }}
                  src={FotoLindedin}
                  alt="Foto de perfil Whatsapp"
                />
                &nbsp;&nbsp;&nbsp;
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "start" }}>
                  <Typography style={{ fontWeight: "500" }}> Fernando Mette </Typography>
                  <Typography variant='body2'> Clique aqui e me envie uma mensagem </Typography>
                </div>
              </div>
            </DivCard>
          </ButtonLink>

        </DivSomeCard>

        <DivSomeCard>

          <ButtonLink onClick={() => openGuiaNewUrl(`https://www.instagram.com/fernando.mette/`)}>
            <DivCard>
              <div style={{ display: "flex", padding: "5px", alignItems: "center" }}>

                <img
                  style={{ width: '30px' }}
                  src={LogoInstagramPNG}
                  alt="logo Instagram"
                />

                &nbsp;&nbsp;
                <Typography variant='h6'>Instagram</Typography>
              </div>

              <div style={{ display: "flex", padding: "5px", alignItems: "center", justifyContent: "flex-start" }}>

                <img
                  style={{ width: '100px', borderRadius: "100px" }}
                  src={FotoPerfilInstagram}
                  alt="Foto de perfil Instagram"
                />
                &nbsp;&nbsp;&nbsp;
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "start" }}>
                  <Typography style={{ fontWeight: "500" }}> Fernando Mette </Typography>
                  <Typography variant='body2'>
                    Um alguém que ama sua companheira, viagens de moto, trilhas esportes radicais, quem mais poderia me acompanhar se não ela.
                  </Typography>
                  &nbsp;
                  <ButtonLink onClick={(e) => { e.stopPropagation(); openGuiaNewUrl(`https://www.instagram.com/jessica.priim/`) }}>
                    <TypographyStyledJehh variant='body2'>Jéssica Prim </TypographyStyledJehh>
                  </ButtonLink>

                </div>
              </div>
            </DivCard>
          </ButtonLink>

          <ButtonLink onClick={() => openGuiaNewUrl("https://www.facebook.com/fernandomette17/")}>
            <DivCard>
              <div style={{ display: "flex", padding: "5px", alignItems: "center" }}>
                <img
                  style={{ width: '30px' }}
                  src={LogoFacebookPNG}
                  alt="Logo facebook"
                />
                &nbsp;&nbsp;
                <Typography variant='h6'>Facebook</Typography>
              </div>

              <div style={{ display: "flex", padding: "5px", alignItems: "center", justifyContent: "flex-start" }}>

                <img
                  style={{ width: '100px', borderRadius: "100px" }}
                  src={PerfilFacebook}
                  alt="Foto de perfil Linkedin"
                />
                &nbsp;&nbsp;&nbsp;
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "start" }}>
                  <Typography style={{ fontWeight: "500" }}> Fernando Mette </Typography>
                  <Typography variant='body2'> Blog pessoal </Typography>
                </div>
              </div>
            </DivCard>
          </ButtonLink>

        </DivSomeCard>

      </div>
    </FnPage>
  );
}