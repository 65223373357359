import React, { useState } from 'react';
import { TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FnPage } from '../Home/Index';
import { openGuiaNewUrl } from '../SocialMedia/Index';
import { css, styled } from 'styled-components';
import LogoWhatsappPNG from "./../../assets/LogoWhatsappPNG.png";
import { PacmanLoader } from 'react-spinners';
import { toast } from 'react-toastify';

const DivSend = styled.div`
  background-Color: #e3e3e3;
  border-Radius: 10px;
  height: 45vh;
  min-height: 150px;
  max-height: 400px;
  max-width: 800px;
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  box-Shadow: rgb(0, 0, 0) 10px 10px 20px 0px;
  justify-content: space-between;
  overflow-x: auto;
  margin-top: 20px;

  @media(max-width: 895px) {
    max-height: unset;
    height: calc(100vh - 170px);
    margin-top: 0px;
  }
`

const ButtonStyled = styled.button`
  width: 100px;
  height: 35px;
  background-color: #d58b03;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: unset;

  ${disabled => disabled.disabled === true && css`
    background-color: #b3b3b3;
    cursor: unset;
    &:hover{
      background-color: #b3b3b3;
    };
  `}
  
  &:hover{
    background-color: orange;
    ${disabled => disabled.disabled === true && css`
      background-color: #b3b3b3;
    `}
  };
`

const TextFieldStyled = styled(TextField)`
  background-Color: white;
  border-Radius: 10px;
`

export default function Contact() {

  const [messageWhats, setMessageWhats] = useState("")
  const [loading, setLoading] = useState(false)

  function sendMessageWhats() {
    setLoading(true)

    setTimeout(() => {
      openGuiaNewUrl(`https://wa.me/47984422902?text=${encodeURIComponent(messageWhats)}`)
    }, 1000)


    setTimeout(() => {
      toast.success("Mensagem foi aberta para envio")
      setMessageWhats("")
      setLoading(false)
    }, 5000)
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  function handleChange(event) {
    var { value } = event.target;
    setMessageWhats(value)
  }

  return (
    <FnPage>

      <div style={{ marginTop: "5px", padding: "10px", display: "flex", flexDirection: "column", alignItems: "center" }}>

        <DivSend>

          <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{ width: '30px' }}
                src={LogoWhatsappPNG}
                alt="Logo Whatsapp"
              />

              &nbsp;&nbsp;
              <Typography variant='h6'>Whatsapp</Typography>
            </div>

            <Typography style={{ margin: "10px" }}> Me envie uma mensagem pelo Whatsapp </Typography>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                margin: "5px 10px 10px 10px",
                borderRadius: "8px",
                padding: "0.5px",
                boxShadow: "rgb(16, 16, 16) 0px 2px 5px 0px"
              }}
            >
              <TextFieldStyled
                multiline
                rows={isMobile ? 12 : 9}
                autoComplete='off'
                id="messageWhatsapp"
                label="Mensagem"
                variant="filled"
                value={messageWhats}
                onChange={handleChange}
                disabled={loading}
              />
            </div>
          </div>

          <div style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "10px" }}>
            <ButtonStyled
              onClick={() => sendMessageWhats()}
              color="primary"
              aria-label="add"
              disabled={!messageWhats.length || loading}
              autoFocus
            >
              {loading ?
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <PacmanLoader
                    color={"#ffc356"}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
                :
                "Enviar"
              }
            </ButtonStyled>
          </div>

        </DivSend>

      </div>
    </FnPage>
  );
}