import React, { useEffect } from 'react'
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom'
import HomePage from './pages/Home/Index';
import { Redirect, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';

// import Login from './Login'
// import PrivateRoute from './components/PrivateRoute'
// import PublicRoute from './components/PublicRoute'

const listRoutes = [
    "",
    "/",
    "/homepage",
]

export default function FBRouter() {

    const history = useHistory()

    useEffect(() => {

        async function routeLink() {

            const h = history?.location?.pathname
            const replaceHistory = (h.substr(-1, 1) === "/") ? (history?.location?.pathname.replace(/.$/, '')) : (h)

            if (!listRoutes.some(l => l === replaceHistory)) {
                toast.warning("Página não encontrada")

                setTimeout(() => {
                    window.location.href = '/homepage'
                }, 1000)
            }
        }

        routeLink()
    }, [history?.location?.pathname, history]);

    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path="/"
                >
                    <Redirect to={{ pathname: "/homepage", }} />
                </Route>

                <Route
                    exact
                    path="/"
                    render={() => <HomePage />}
                />

                <Route
                    exact
                    path="/homepage"
                    render={() => <HomePage />}
                />

                {/* <PrivateRoute exact path="/dashboard" component={Dashboard} /> */}

                {/* <PublicRoute
                    restricted={false}
                    component={SalesIntelligencesCompanyBuildings}
                    path="/inteligencia-de-vendas/empreendimentos/:nick_name"
                    exact
                /> */}

            </Switch>
        </Router>
    )
}
