import React from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import Router from "./Router";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "./contexts/theme";

// import { reducer, initialState } from "./reducers/index";
// import "bootstrap/dist/css/bootstrap.min.css";
// import 'moment/locale/pt-br'
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import { Chart as ChartJS } from "chart.js";
// import LoginContext from "./contexts/auth";
// import MobileContext, { initialState as mobileInitialState } from "./contexts/mobile";
// import ChartDataLabels from 'chartjs-plugin-datalabels';
// import { ThemeContext } from "./theme";

// ChartJS.register(
//     ChartDataLabels,
//     {
//         id: 'custom_canvas_background_color',
//         beforeDraw: (chart) => {
//             const ctx = chart.canvas.getContext('2d');
//             ctx.save();
//             ctx.globalCompositeOperation = 'destination-over';
//             ctx.fillStyle = 'white';
//             ctx.fillRect(0, 0, chart.width, chart.height);
//             ctx.restore();
//         }
//     }
// );

function App() {
    // const [state, dispatch] = React.useReducer(reducer, null, initialState);
    // const [mainDrawerOpen, setMainDrawerOpen] = useState( mobileInitialState.mainDrawerOpen );
    // const mobile = { mainDrawerOpen: [mainDrawerOpen, setMainDrawerOpen] };

    return (

        // <ThemeContext.Provider value={theme}>
        // <LoginContext.Provider value={{ state, dispatch, }}>
        //<MobileContext.Provider value={mobile}>

        <ThemeProvider theme={theme}>
            <Router
                // state={state}
                // dispatch={dispatch}
            />
            <ToastContainer autoClose={5000} draggable transition={Slide} />
        </ThemeProvider>

        // </MobileContext.Provider>
        // </LoginContext.Provider>
        // </ThemeContext.Provider>
    );
}

export default App;
