import styled from 'styled-components'
import React from 'react';
import { Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';

const IconDeleteStyle = styled(Delete)`
  width: 19px;
  border-radius: 6px;
  opacity: 65%;
  &:hover{
    background-color: rgba(0, 0, 0, 0.1);
  };
`

const ButtonStyle = styled.button`
  color: #081630;
  padding: 0;
  text-decoration: none;
  background-color: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer;
  &:hover{
    text-decoration: none;
    color: orange;
  };
`

export const ButtonLink = React.forwardRef((props, ref) => {
  return <ButtonStyle {...props} className="fb-button-link" ref={ref} />
})

export const IconButton = styled(ButtonLink)`
  &:not(:first-child) {
    margin-left: 5px;
  }
  > svg {
      padding: 1px;
      width: 25px;
      border-radius: 6px;
      opacity: 65%;
      &:hover{
        background-color: rgba(0, 0, 0, 0.1);
      };
  }
`

const style = { marginRight: "0px", marginLeft: "5px" }

export const TrashCanButton = ({ opacity, title, ...props }) => (
  <ButtonLink style={style} {...props}>
    <Tooltip title={title}>
      <IconDeleteStyle color="error" opacity={opacity} />
    </Tooltip>
  </ButtonLink>
)


